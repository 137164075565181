import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeysTasklist } from "./config/index";
import { PumpApi } from "@/api";
import Item from "./components/item.vue";
import { formatTextareaToArray } from '@/utils/help';
export default {
  name: "pumpIndex",
  components: {
    Item: Item
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      formLoading: false,
      timer: null,
      searchKeys: searchKeysTasklist,
      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      isDeleteVisible: false,
      deleting: false,
      defaultIndex: 1,
      types: [{
        id: -1,
        name: '全部'
      }, {
        id: 0,
        name: '待处理轮次'
      }, {
        id: 1,
        name: '已处理轮次'
      }],
      officalWallet: '' // pump钱包地址
    };
  },

  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    filteredCertificationList: function filteredCertificationList() {
      return this.certificationList.filter(function (it) {
        return it.id > 0;
      });
    },
    modalTitle: function modalTitle() {
      return this.action == "add" ? "新增PUMP" : this.action == "edit" ? "编辑PUMP" : this.action == "cycles" ? "Round\u65F6\u95F4\u6BB5\u67E5\u8BE2" : this.action == 'status' ? 'PUMP卡片展示状态更新' : "";
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    }
  },
  created: function created() {
    this.getList();
    this.getOpenOfficeWallet();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this.selectedRowKeys = [];
          _this.loading = true;
          var params = _objectSpread({
            page: _this.page,
            size: _this.size
          }, values);
          if (_this.status >= 0) {
            params['status'] = _this.status;
          }
          if (params !== null && params !== void 0 && params.uids) {
            params.uids = formatTextareaToArray(params.uids);
          }
          PumpApi.tasklist(_objectSpread({}, params)).then(function (res) {
            var list = (res === null || res === void 0 ? void 0 : res.list) || [];
            // .map(it => {
            //   it.pumpethamount = 0.0012;
            //   return it
            // })
            _this.loading = false;
            _this.dataSource = list;
            _this.total = (res === null || res === void 0 ? void 0 : res.total) || _this.total;
          }).catch(function () {
            _this.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    getOpenOfficeWallet: function getOpenOfficeWallet() {
      var _this2 = this;
      PumpApi.openOfficeWallet({}).then(function (data) {
        _this2.officalWallet = data;
      }).catch(function () {
        _this2.officalWallet = '';
      }).finally(function () {
        setTimeout(function () {
          // this.loading = false;
        }, 3000);
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "edit") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == 'del') {
        this.tipsDesc = "\u786E\u5B9A\u5220\u9664\u8BE5[id:".concat(this.editId, "]\u6570\u636E\uFF1F");
        this.isDeleteVisible = true;
        this.source = {};
      } else {
        this.modalVisible = true;
        this.source = {};
      }
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/handleCancle: function handleCancle() {
      this.isDeleteVisible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;
      this.deleting = true;
      PumpApi.delete({
        id: this.editId
      }).then(function () {
        _this3.isDeleteVisible = false;
        _this3.getList();
      }).catch(function (e) {}).finally(function () {
        _this3.deleting = false;
      });
    },
    handleClickTab: function handleClickTab(item, index) {
      this.defaultIndex = index;
      this.handleSearch();
    }
  }
};