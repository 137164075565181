// Fanshood合约ABI
export var fanshoodAbi = [{
  inputs: [{
    internalType: 'address',
    name: '_protocolFeeDestination',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_rewardFeeDestination',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_fanshoodService',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_fanshoodFinancial',
    type: 'address'
  }, {
    internalType: 'address',
    name: '_fanshoodAirdrop',
    type: 'address'
  }],
  stateMutability: 'nonpayable',
  type: 'constructor'
}, {
  inputs: [{
    internalType: 'address',
    name: 'owner',
    type: 'address'
  }],
  name: 'OwnableInvalidOwner',
  type: 'error'
}, {
  inputs: [{
    internalType: 'address',
    name: 'account',
    type: 'address'
  }],
  name: 'OwnableUnauthorizedAccount',
  type: 'error'
}, {
  inputs: [],
  name: 'ReentrancyGuardReentrantCall',
  type: 'error'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    internalType: 'address',
    name: 'previousOwner',
    type: 'address'
  }, {
    indexed: true,
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'OwnershipTransferred',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'subject',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'bookTime',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'openTime',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'tradeTime',
    type: 'uint256'
  }],
  name: 'PreReleaseTrade',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'subject',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'pumpAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'totalPumpAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'pumpIndex',
    type: 'uint256'
  }],
  name: 'Pump',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: false,
    internalType: 'address',
    name: 'trader',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'address',
    name: 'subject',
    type: 'address'
  }, {
    indexed: false,
    internalType: 'bool',
    name: 'isBuy',
    type: 'bool'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'hoodAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'ethAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'protocolEthAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'rewardEthAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'subjectEthAmount',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'supply',
    type: 'uint256'
  }, {
    indexed: false,
    internalType: 'uint256',
    name: 'tradeTime',
    type: 'uint256'
  }],
  name: 'Trade',
  type: 'event'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'bytes32[]',
    name: '_proof',
    type: 'bytes32[]'
  }],
  name: 'airdrop',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'address[]',
    name: '_addresses',
    type: 'address[]'
  }],
  name: 'airdropByFanshood',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'bookHoods',
  outputs: [{
    internalType: 'bool',
    name: 'isBook',
    type: 'bool'
  }, {
    internalType: 'uint256',
    name: 'bookTime',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'openTime',
    type: 'uint256'
  }, {
    internalType: 'bytes32',
    name: 'whitelistRoot',
    type: 'bytes32'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'buyHoods',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'buyRewardFeePercent',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'fanshoodAirdrop',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'fanshoodFinancial',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'fanshoodService',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'getBuyPrice',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'getBuyPriceAfterFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }],
  name: 'getIndex',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'supply',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'index',
    type: 'uint256'
  }],
  name: 'getPrice',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'pure',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'getSellPrice',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'getSellPriceAfterFee',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }, {
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'hoodsBalance',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'hoodsSupply',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'maxPreAmount',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'owner',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: 'bookTime',
    type: 'uint256'
  }, {
    internalType: 'uint256',
    name: 'openTime',
    type: 'uint256'
  }, {
    internalType: 'bytes32',
    name: 'whitelistRoot',
    type: 'bytes32'
  }],
  name: 'preRelease',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'protocolFeeDestination',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'protocolFeePercent',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }],
  name: 'pump',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'renounceOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'rewardFeeDestination',
  outputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'uint256',
    name: 'amount',
    type: 'uint256'
  }],
  name: 'sellHoods',
  outputs: [],
  stateMutability: 'payable',
  type: 'function'
}, {
  inputs: [],
  name: 'sellRewardFeePercent',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_buyRewardFeePercent',
    type: 'uint256'
  }],
  name: 'setBuyRewardFeePercent',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_fanshoodAirdrop',
    type: 'address'
  }],
  name: 'setFanshoodAirdrop',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_fanshoodFinancial',
    type: 'address'
  }],
  name: 'setFanshoodFinancial',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_fanshoodService',
    type: 'address'
  }],
  name: 'setFanshoodService',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_feeDestination',
    type: 'address'
  }],
  name: 'setFeeDestination',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_amount',
    type: 'uint256'
  }],
  name: 'setMaxPreAmount',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercent',
    type: 'uint256'
  }],
  name: 'setProtocolFeePercent',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '_rewardFeeDestination',
    type: 'address'
  }],
  name: 'setRewardFeeDestination',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_sellRewardFeePercent',
    type: 'uint256'
  }],
  name: 'setSellRewardFeePercent',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'uint256',
    name: '_feePercent',
    type: 'uint256'
  }],
  name: 'setSubjectFeePercent',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'subjectFeePercent',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'subjectIndex',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: '',
    type: 'address'
  }],
  name: 'subjectPump',
  outputs: [{
    internalType: 'uint256',
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'newOwner',
    type: 'address'
  }],
  name: 'transferOwnership',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    internalType: 'address',
    name: 'hoodsSubject',
    type: 'address'
  }, {
    internalType: 'bytes32',
    name: 'whitelistRoot',
    type: 'bytes32'
  }],
  name: 'updateWhiteList',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function'
}];